// our components
require('../../section-specific/travel-medical/quote-form_travel-medical');
require('../../components/forms/component.quote-form-age-modal');
require('../../components/forms/component.quote-form-covid-location');
import quoteFormMixin from '../../quote-form-mixin';
import { Vuelidate } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Vue from 'vue';
Vue.use(Vuelidate);

import datePicker from '../../components/forms/component.date-picker.vue';

import MainVue from '../../main-vue';
import { monthDate, numbersWithDashSlash, noAlphabets, isAfterField } from '../../helper-functions/helper-functions';
import { setItemSessionStorage } from '../../helper-functions/services';

new MainVue({
    el: '#app',
    mixins: [quoteFormMixin],
    components: {
        'date-picker': datePicker,
    },
    data() {
        return {
            formWidget: {
                citizenshipCountry: "",
                startDate: "",
                endDate: "",
                dateRange: "",
            },
            section: "visitorUSA"
        }
    },
    validations () {
        let validations = {
            formWidget: {
                citizenshipCountry: {
                    required
                },
                startDate: {
                    required,
                    noAlphabets,
                    numbersWithDashSlash,
                    monthDate,
                },
                endDate: {
                    required,
                    noAlphabets,
                    numbersWithDashSlash,
                    monthDate,
                    isAfterStartDate: isAfterField('startDate')
                },
            }
        }
        return validations;
    },
    methods: {
        getQutoes () {
            const qFormLang = window.qFormLang;
            this.$v.formWidget.$touch();
            if (!this.$v.$invalid) {
                const data = {
                    coverageArea: "1",
                    currentLang: window.globalLanguage.lang,
                    startDate: this.formWidget.startDate,
                    endDate: this.formWidget.endDate,
                    citizenshipCountry: this.formWidget.citizenshipCountry,
                    primaryDestination: "USA",
                    widget: true
                };
                setItemSessionStorage('insubuy-travel-quote-form-resultObjs', JSON.stringify({
                    ...data,
                    presection: this.section,
                }));
                Vue.nextTick(()=> {
                    window.location.href = `${qFormLang.getNewQuoteForm}?presection=${this.section}`
                })
            }
        }
    }
});

new MainVue({
    el: '#home-card-blocks-and-guide',
    data: function() {
        return {
            affiliateTripInsurance: ibJS.affiliateTripInsurance,
            affiliateAnnualMultitrip: ibJS.affiliateAnnualMultitrip,
            affiliateDental: ibJS.affiliateDental,
            affiliateGroupMedical: ibJS.affiliateGroupMedical,
            affiliateSchengeVisa: ibJS.affiliateSchengeVisa,
            affiliateMissionaryLongTerm: ibJS.affiliateMissionaryLongTerm,
            affiliateGroupMedicalInsurance: ibJS.affiliateGroupMedicalInsurance,
            affiliateVisitorsToUsa: ibJS.affiliateVisitorsToUsa,
            affiliateVisitorOutsideUSA: ibJS.affiliateVisitorOutsideUSA,
            affiliateIndividualMedical: ibJS.affiliateIndividualMedical,
            affiliateFianceVisa: ibJS.affiliateFianceVisa,
            affiliateJVisa: ibJS.affiliateJVisa,
            affiliateIntlStudent: ibJS.affiliateIntlStudent,
            isFAQItemVisible: null,
        }
    },
    methods: {
        getIntroCardsCount: function(){
            //Initiate counter
            var counter = 0;

            //Visitors To USA Section
            if(window.globalLanguage.section == "visitorUSA") {
                //Arrays of true/false block section values
                var sectionCards = [this.affiliateTripInsurance,this.affiliateAnnualMultitrip,this.affiliateDental,this.affiliateGroupMedical];
            }
            else if(window.globalLanguage.section == "newImmigrants") {
                var sectionCards = [this.affiliateIndividualMedical,this.affiliateFianceVisa,this.affiliateDental,this.affiliateTripInsurance];
            }
            else if(window.globalLanguage.section == "travelOutsideUSA") {
                var sectionCards = [this.affiliateTripInsurance,this.affiliateAnnualMultitrip,this.affiliateSchengeVisa,this.affiliateGroupMedical];
            }
            else if(window.globalLanguage.section == "schengen") {
                var sectionCards = [this.affiliateVisitorOutsideUSA,this.affiliateAnnualMultitrip,this.affiliateTripInsurance,this.affiliateGroupMedicalInsurance];
            }
            else if(window.globalLanguage.section == "studyAbroad") {
                var sectionCards = [this.affiliateSchengeVisa,this.affiliateTripInsurance,this.affiliateJVisa,this.affiliateIntlStudent];
            }            
            else if(window.globalLanguage.section == "missionaryTravel") {
                var sectionCards = [this.affiliateMissionaryLongTerm,this.affiliateTripInsurance,this.affiliateGroupMedicalInsurance,this.affiliateVisitorsToUsa];
            }
            else if(window.globalLanguage.section == "covid19") {
                var sectionCards = [this.affiliateMissionaryLongTerm,this.affiliateTripInsurance,this.affiliateGroupMedicalInsurance,this.affiliateVisitorsToUsa];
            }

            //Loop and count true/false values
            sectionCards.forEach(v => v ? counter++ : v);

            return counter;
        },
        getIntroCardsModifierClass(itemCount, masterClass) {
            //Return modifier class name to adjust intro card styles
            if(itemCount==1) {
                return masterClass + "--one";
            }
            else if(itemCount==2) {
                return masterClass + "--two";
            }
            else if(itemCount==3) {
                return masterClass + "--three";
            }
            else if(itemCount==4) {
                return masterClass + "--four";
            }
        },
        toggleFAQItem: function(value) {
            
            if(this.isFAQItemVisible!==value){
                this.isFAQItemVisible = value;
            }
            else {
                this.isFAQItemVisible = null;
            }
        
        },
    }
});