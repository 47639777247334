import Vue from 'vue';
import VueResource from 'vue-resource';
import { validationMixin, Vuelidate } from 'vuelidate';
import { required, between } from 'vuelidate/lib/validators';

Vue.use(VueResource);
Vue.use(Vuelidate);

import datePicker from '../../components/forms/component.date-picker.vue';
import ageSwitch from '../../components/forms/component.age-birthdate-input-switch.vue';

import {
    onlyNumbers,
    isAfterField,
    mustBeChecked,
    spouseAgeValidation,
    convertDateByLang,
    monthDate,
    numbersWithDashSlash,
    noAlphabets,
    checkAgent,
    isTruthy,
    dateNotBefore
} from '../../helper-functions/helper-functions';
import {
    createUpdateQuoteService,
    initializeFormFromStorage,
    checkExpiredAndTriggerValidation,
    getItemSessionStorage,
    removeKeySessionStorage
} from '../../helper-functions/services';

import { LoginModalMixin } from '../../section-specific/applications/mixins/loginModal';
import { QuoteFormMixin } from '../quote-form/';



const defs = {}; // form defaults
defs.counter = 0;
switch (window.globalLanguage.section) {
    case 'visitorUSA':
        defs.policyMaximum = ibJS.visiToUsaPolicyMaximum ? ibJS.visiToUsaPolicyMaximum : '20';
        defs.citizenshipCountry = ibJS.visiToUsaCitizenship ? ibJS.visiToUsaCitizenship : '';
        defs.residenceCountry = '2';
        //defs.arrivalTime = '0'; removing default set to yet to arrive INSUBUY-4057
        defs.arrivalInUSA = '0';
        break;
    case 'newImmigrants':
        //defs.arrivalTime = '0'; removing default set to yet to arrive INSUBUY-4057
        defs.coverageArea = '1';
        defs.citizenshipCountry = '2';
        defs.residenceCountry = '1';
        defs.arrivalInUSA = '0';
        defs.policyMaximum = ibJS.newImmiPolicyMaximum ? ibJS.newImmiPolicyMaximum : '';
        break;
    case 'travelOutsideUSA':
        // including these to pass fe validation
        defs.citizenshipCountry = ibJS.traOutUsaCitizenship ? ibJS.traOutUsaCitizenship : '';
        defs.homeCountry = ibJS.traOutUsaHomeCountry ? ibJS.traOutUsaHomeCountry : '';
        defs.coverageArea = '3';
        defs.arrivalInUSA = '0';
        // defs.policyMaximum = '70';
        defs.policyMaximum = ibJS.traOutUsaCitizenship ? ibJS.traOutUsaPolicyMaximum : '70';
        break;
    case 'schengen':
        defs.policyMaximum = ibJS.schVisaPolicyMaximum ? ibJS.schVisaPolicyMaximum : '20';
        defs.citizenshipCountry = ibJS.schVisaCitizenship ? ibJS.schVisaCitizenship : '';
        defs.homeCountry = ibJS.schVisaHomeCountry ? ibJS.schVisaHomeCountry : '';
        defs.coverageArea = '3';
        // including these to pass fe validation
        defs.arrivalInUSA = '0';
        break;
    case 'studyAbroad':
        // including these to pass fe validation
        defs.coverageArea = '3';
        defs.arrivalInUSA = '0';
        defs.policyMaximum = ibJS.studyAbroadPolicyMaximum ? ibJS.studyAbroadPolicyMaximum : '';
        defs.citizenshipCountry = ibJS.studyAbroadCitizenship ? ibJS.studyAbroadCitizenship : '';
        defs.homeCountry = ibJS.studyAbroadHomeCountry ? ibJS.studyAbroadHomeCountry : '';
        break;
    case 'missionaryTravel':
        // including these to pass fe validation
        defs.arrivalInUSA = '0';
        defs.policyMaximum = ibJS.missionTravelPolicyMaximum ? ibJS.missionTravelPolicyMaximum : '70';
        defs.citizenshipCountry = ibJS.missionTravelCitizenship ? ibJS.missionTravelCitizenship : '';
        defs.homeCountry = ibJS.missionTravelHomeCountry ? ibJS.missionTravelHomeCountry : '';
        defs.coverageArea = '3';
        break;
    case 'covid19':
        // including these to pass fe validation
        defs.arrivalInUSA = '0';
        defs.policyMaximum = ibJS.covidPolicyMaximum ? ibJS.covidPolicyMaximum : '20';
        defs.citizenshipCountry = ibJS.covidCitizenship ? ibJS.covidCitizenship : '';
        // defs.homeCountry = ibJS.covidHomeCountry ? ibJS.covidHomeCountry : '';
        defs.coverageArea = '';
        break;
    default:
}

Vue.component('quote-form-travel-medical', {
    components: {
        'date-picker': datePicker,
        'age-switch': ageSwitch
    },
    props: ['ss-criteria', 'is-results-page','is-widget'],
    mixins: [LoginModalMixin, QuoteFormMixin],

    data: function () {
        return {
            form: {
                primaryAge: null,
                primaryBday: '',
                spouseAge: null,
                spouseBday: '',
                numChildren: '',
                startDate: '',
                endDate: '',
                primaryDestination: '',
                policyMaximum: defs.policyMaximum || '',
                residenceCountry: defs.residenceCountry || '',
                homeCountry: defs.homeCountry || '',
                citizenshipCountry: defs.citizenshipCountry || '',
                coverageArea: defs.coverageArea || '',
                arrivalInUSA: defs.arrivalInUSA || '',
                arrivalTime: defs.arrivalTime || '',
                mailingState: '',
                physicalPresenceState: '',
                section: window.globalLanguage.section || '',
                showSpouseAge: false,
                showNumChildren: false,
                currentLang: window.globalLanguage.lang,
                residenceState:''
            },
            formSending: false,
            showAges: false,
            agesOpened: false,
            showPrimaryByAge: true,
            showPrimaryAgeSwitch: false,
            showPrimaryBdSwitch: false,
            showSpouseByAge: true,
            showCoverageArea: false,
            covAreaOpened: false,
            showHomeCountry: false,
            showLocation: false,
            homeCountryOpened: false,
            locationOpened: false,
            coverageAreaInfo: ibJS.coverageAreaDefault,
            homeCountryInfo: ibJS.homeCountryDefault,
            showMobileError:false,
            qResultsLang: window.qResultsLang || null,
            primaryMinorAge: false,
            spouseMinorAge: false,
            priMinWithKid: false,
            emitFrmLocModal: false,
            fromssCriteria: false,
        }
    },
    validations: function () {
        var validations = {
            form: {
                startDate: {
                    required,
                    monthDate,
                    numbersWithDashSlash,
                    noAlphabets
                },
                endDate: {
                    required,
                    monthDate,
                    numbersWithDashSlash,
                    noAlphabets,
                    isAfterStartDate: isAfterField('startDate')
                },
                primaryDestination: {
                    required
                },
                citizenshipCountry: {
                    required
                },
                policyMaximum: {
                    required
                },
                primaryAge: {
                    required,
                    onlyNumbers
                },
                spouseAge: {
                    spouseAgeValidation
                },
                mailingState: {
                    required
                },
                coverageArea: {
                    required
                },
                residenceCountry: {},
                homeCountry: {},
                arrivalInUSA: {
                    required
                },
                arrivalTime: {
                    required
                },
                physicalPresenceState: {},
                numChildren:{
                    between: typeof between === 'function' ? between(1,7) : null
                },
                residenceState: {required},
            }
        };

        // Visitors to USA
        if (this.form.section === 'visitorUSA') {
            // require by default
            validations.form.residenceCountry.required = required;

            if (this.form.arrivalTime != '0' && this.form.arrivalTime != '') {
                validations.form.physicalPresenceState.required = required;
            }
        }

        // Travel Outside USA
        if (['travelOutsideUSA','schengen','studyAbroad'].indexOf(this.form.section) > -1) {

            // require by default
            validations.form.homeCountry.required = required;
            validations.form.physicalPresenceState.required = required;

            if (this.form.homeCountry !== 'USA' && this.form.arrivalTime == '0') {
                // do not require
                if  (validations.form.physicalPresenceState.required) {
                    delete validations.form.physicalPresenceState.required;
                }
            }
            if (this.form.homeCountry === 'USA' && this.form.arrivalTime != '0') {
                // do not require
                if (validations.form.physicalPresenceState.required) {
                    delete validations.form.physicalPresenceState.required;
                }
            }

            if(this.form.homeCountry === 'USA'){
                validations.form.residenceState.required = required;
            }
            else{
                delete validations.form.residenceState.required;
            }
        }

        if (this.form.section === 'missionaryTravel' || this.form.section === 'covid19') {
            if (this.form.coverageArea === '2' || 
                (this.form.section === 'covid19' && (this.form.coverageArea === '1' || this.form.coverageArea === '5'))
            ) {
                validations.form.arrivalTime.required = required;
            }else if(this.form.coverageArea === '3'){
                validations.form.homeCountry.required = required;                
            }

            if (this.showPhysicalPresenceState) {
                validations.form.physicalPresenceState.required = required;
            }

            if(this.form.homeCountry === 'USA'){
                validations.form.residenceState.required = required;
            }
            else{
                delete validations.form.residenceState.required;
            }
        }

        if(['travelOutsideUSA','schengen','studyAbroad','missionaryTravel'].indexOf(this.form.section) < 0){
            delete validations.form.residenceState.required;
        }

        if(this.form.showSpouseAge){
            validations.form.spouseAge.required = required;
            validations.form.spouseAge.onlyNumbers = onlyNumbers;
        }
        if(this.form.showNumChildren){
            validations.form.numChildren.required = required;
        }

        if(this.form.numChildren > 0){
            delete validations.form.numChildren.required;
            delete validations.form.numChildren.between;
        }

        //SENTRY-55
        //If validation is null, rather than breaking the form, the validation will be deleted and a backend validation will show.
        for(let key in validations.form) {
            //Null check for direct children of validation.form i.e. validations.form.test=null
            if(validations.form.hasOwnProperty(key)){
                if(validations.form[key] === null || validations.form[key] === undefined){
                    delete validations.form[key]
                }
                else {
                    //Null check for nested children of validation.form. i.e. validations.form.spouseAge.test = null
                    for(let key2 in validations.form[key]){
                        if(validations.form[key][key2] === null || validations.form[key][key2] === undefined){
                            delete validations.form[key][key2]
                        }
                    }
                }
            }
        }

        if(['travelOutsideUSA','covid19','missionaryTravel','studyAbroad'].indexOf(this.form.section) > -1) {
            if(this.form.primaryDestination === 'THA' && ['5','20','30'].indexOf(this.form.policyMaximum.toString()) > -1 && this.form.startDate && this.form.startDate.length === 10 && !dateNotBefore('11/01/2021')(this.form.startDate)) {
                validations.form.policyMaximum.min100PolicyMax = isTruthy;
            } else {
                delete validations.form.policyMaximum.min100PolicyMax;
            }
            // if(this.form.primaryDestination === 'THA' && ['5'].indexOf(this.form.policyMaximum.toString()) > -1 && this.form.startDate.length === 10 && dateNotBefore('11/01/2021')(this.form.startDate)) {
            //     validations.form.policyMaximum.min50PolicyMax = isTruthy;
            // } else {
            //     delete validations.form.policyMaximum.min50PolicyMax;
            // }
        } 

        if(['travelOutsideUSA','covid19','missionaryTravel','studyAbroad'].indexOf(this.form.section) < 0) {
            delete validations.form.primaryDestination.required;
        }

        return validations; 
    },
    watch: {
        ssCriteria: function (ssCriteria) {
            this.fromssCriteria = true;
            for (let key in ssCriteria) {
                if (typeof this.form[key] !== 'undefined') {
                    this.form[key] = ssCriteria[key];
                    if(key === "numChildren") {
                        if(this.form[key] == 0) {
                            this.form[key] = "";    
                        }
                    }
                }
            }

            if (ssCriteria.numChildren && ssCriteria.numChildren*1 > 0) {
                this.form.showNumChildren = true;
            }

            this.agesOpened = true;
            this.covAreaOpened = true;
            this.homeCountryOpened = true;
            this.locationOpened = true;
            if(defs.counter == 0){
                this.storeFormValuesInSession(this.form);
                defs.counter += 1;
            }

        },
        'form.primaryDestination': function (val, oldVal) {
            if(this.form.section === 'missionaryTravel') {
                if(val === 'USA') {
                    this.form.coverageArea = '2';
                } else {
                    this.form.coverageArea = '3';
                    this.form.homeCountry = '';
                }
            }

            if (oldVal === 'CRI') {
                removeKeySessionStorage('quarantineCoverageCosta');
                let narrowSection = window.sessionStorage[window.globalLanguage.sectionNarrow];
                if (narrowSection) {
                    narrowSection = JSON.parse(narrowSection);
                    narrowSection.covidQuarantine = false;
                    window.sessionStorage[window.globalLanguage.sectionNarrow] = JSON.stringify(narrowSection);
                }
            }
        },
        'form.arrivalTime': function (val) {
            if (!this.emitFrmLocModal && !this.fromssCriteria) {
                this.form.physicalPresenceState = "";
            } else if (this.emitFrmLocModal) {
                this.emitFrmLocModal = false;
            }
            this.fromssCriteria = false;
        }
    },
    created: function() {
        var self = this;
      //Add Additionals for - Different plan same person - MyAccount re-purchase
        if(ibJS.travellerDetails && ibJS.travellerDetails.length) {
            let insureds = ibJS.travellerDetails.filter((item, index) => {
                return item.travelerType || item.travelerType === '';
            });
            if(insureds.length !== ibJS.travellerDetails.length) {
                //Add Additionals for - Different plan same person - MyAccount re-purchase --no-travelerType
                    if(ibJS.travellerDetails && ibJS.travellerDetails.length > 1) {
                        this.form.primaryAge = window.ibJS.age;
                        this.agesOpened = true;

                        this.form.showSpouseAge = true;
                        this.form.spouseAge = ibJS.travellerDetails[1].age;

                        if(ibJS.travellerDetails.length > 2) {
                            let numChildren10_17 = ibJS.travellerDetails.filter((item, index) => {
                                return item.age > 9 && item.age < 18;
                            });
                            let numChildrenUnder10 = ibJS.travellerDetails.filter((item, index) => {
                                return item.age < 10;
                            });
                            this.form.showNumChildren = true;
                            this.form.numChildren10_17 = numChildren10_17.length ? numChildren10_17.length.toString() : '';
                            this.form.numChildrenUnder10 = numChildrenUnder10.length ? numChildrenUnder10.length.toString() : '';
                            this.form.numChildren = numChildren10_17.length + numChildrenUnder10.length;
                        }
                    }
            } else {
                    //Add Additionals for - Different plan same person - MyAccount re-purchase --with-travelerType
                    let primary = ibJS.travellerDetails.filter((item, index) => {
                        return item.travelerType === 'primary' || item.travelerType === '';
                    });

                    let spouse = ibJS.travellerDetails.filter((item, index) => {
                        return item.travelerType === 'spouse';
                    });

                    let children = ibJS.travellerDetails.filter((item, index) => {
                        return item.travelerType === 'child';
                    });

                    if(primary.length && spouse.length) {
                        this.form.primaryAge = window.ibJS.age;
                        this.agesOpened = true;

                        this.form.showSpouseAge = true;
                        this.form.spouseAge = ibJS.travellerDetails[1].age;

                        if(children.length) {
                            let noOfChildren = children.length;
                            this.form.showNumChildren = true;
                            this.form.numChildren = noOfChildren.toString();
                        } 
                    }
                    else if(!primary.length && spouse.length) {
                        this.form.showSpouseAge = false;
                        this.form.primaryAge = spouse[0].age;
                        this.agesOpened = true;

                        if(children.length) {
                            let noOfChildren = children.length;
                            this.form.showNumChildren = true;
                            this.form.numChildren = noOfChildren.toString();
                        }
                    } 
                    else if(primary.length && !spouse.length){
                        this.form.showSpouseAge = false;
                        this.form.primaryAge = window.ibJS.age;
                        this.agesOpened = true;

                        if(children.length) {
                            let noOfChildren = children.length;
                            this.form.showNumChildren = true;
                            this.form.numChildren = noOfChildren.toString();
                        }
                    } 
                    else {
                        this.form.showSpouseAge = false;
                        this.form.primaryAge = ibJS.travellerDetails[0].age;
                        this.agesOpened = true;

                        if(children.length > 1) {
                            let noOfChildren = children.length-1;
                            this.form.showNumChildren = true;
                            this.form.numChildren = noOfChildren.toString();
                        }
                    }
            }
        }

        if(this.form.homeCountry) {
            this.homeCountryOpened = true;
            this.homeCountryInfo = $("#HomeCountry option[value='" + this.form.homeCountry + "']:first").text();
        }

        // SENTRY-943
        if (initializeFormFromStorage && typeof(initializeFormFromStorage) == 'function') {
            initializeFormFromStorage.call(this, window.globalLanguage.sectionCriteriaKey, function () {
                self.agesOpened = true;
                self.covAreaOpened = true;
                self.homeCountryOpened = true;
                self.locationOpened = true;
                self.form.primaryBday = '';
                self.form.spouseBday = '';
                convertDateByLang(self.form, window.globalLanguage.lang);
            });
        }
        
        if (checkExpiredAndTriggerValidation && typeof(checkExpiredAndTriggerValidation) == 'function') {
            checkExpiredAndTriggerValidation.call(this);
        }

        $(document).ready(function () {
            $('.o-Form').on('click', function () {
                self.showAges = false;
                self.showCoverageArea = false;
                self.showHomeCountry = false;
                self.showLocation = false;
            });

            $('.js-Stop-propagation').on('click', function (e) {
                e.stopPropagation();
            });
        });
        
        // set defaults when coming form myAccount - see MYACCOUNT-117
        if(window.ibJS && window.ibJS.age && !window.ibJS.travellerDetails) {
            self.form.primaryAge = window.ibJS.age;
            self.agesOpened = true;
        }
        if(window.ibJS && window.ibJS.citizenship) {
            self.form.citizenshipCountry = window.ibJS.citizenship;
        }
        if(window.ibJS && window.ibJS.malingAddressState) {
            self.form.mailingState  = window.ibJS.malingAddressState ;
        }

        // set message if expiredDate is set to true by submitting the form on creation
        try {
            if(window.sessionStorage?.getItem('expiredDate') == 'true'){
                window.sessionStorage.removeItem('expiredDate');
                createUpdateQuoteService.call(this, window.ibJS.createUpdateQuoteAPIPath, this.form, window.globalLanguage.sectionCriteriaKey, window.ibJS.quoteResultsPagePath);
            }
        } catch(e) {
            createUpdateQuoteService.call(this, window.ibJS.createUpdateQuoteAPIPath, this.form, window.globalLanguage.sectionCriteriaKey, window.ibJS.quoteResultsPagePath);
        }

        if (this.isWidget){
            this.form.policyMaximum = '';
        }
        // INSUBUY-7134
        const quotePlan = getItemSessionStorage('quotePlan');
        if (quotePlan && quotePlan === 'covid19' && this.form.section === 'covid19') {
            this.form.coverageArea = 3;
        }

        // Quote form modal listener - Age block
        this.$root.$on('set-quote-age', (obj, skip) => {
            if (!skip) this.form = Object.assign(this.form, obj);
            this.showAges = false;
        })
        this.$root.$on('set-covid-location-modal', (obj, skip) => {
            if (!skip) {
                this.emitFrmLocModal = true;
                this.form = Object.assign(this.form, obj.form);
                this.coverageAreaInfo = obj.coverageAreaInfo;
            }
            this.showLocation = false;
        })

        if (this.form.section === 'travelOutsideUSA') {
            // INSUBUY-6721
            const destiCountry = getItemSessionStorage('destiCosta');
            const residCountry = getItemSessionStorage('residenceCosta');
            if (destiCountry) {
                this.form.primaryDestination = 'CRI';
                removeKeySessionStorage('destiCosta');
                this.form.policyMaximum = '20';
            }
            if (residCountry) {
                this.form.homeCountry = 'USA';
                removeKeySessionStorage('residenceCosta');
            }
        }

    },
    mounted: function() {
        if (window.ibJS && typeof window.ibJS.isResultsPage === 'undefined' && typeof(checkAgent) == 'function') {
            checkAgent.call(this, [false]);       
        }
    },
    methods: {
        createQuote: function (e) {
            e.preventDefault();

            if(!this.form.showNumChildren){
                this.form.numChildren = '';
            }
            if(this.form.spouseAge == -1){
                this.form.spouseAge = null;
            }

            if(!this.form.showSpouseAge){
                this.form.spouseAge = null;
                this.form.spouseBday = '';
            }

            if(this.form.section === 'missionaryTravel' && this.form.coverageArea == '2'){
                this.form.residenceCountry = '2';
            }else if(this.form.section === 'missionaryTravel' && this.form.coverageArea == '3'){
                this.form.residenceCountry = '';
            }

            this.$v.$touch();
            if (!this.$v.$invalid && !this.calculateAges) {
                var data = JSON.stringify(this.form);
                createUpdateQuoteService.call(this, window.ibJS.createUpdateQuoteAPIPath, JSON.parse(data), window.globalLanguage.sectionCriteriaKey, window.ibJS.quoteResultsPagePath);
                this.$emit('close-edit-quote');
                if (ibJS.fullQuoteId) {
                    this.$http.post('/api/myaccount/remove-dup-quote/', {fullQuoteId: ibJS.fullQuoteId || ''});
                }
            }else{
                this.showMobileError = true;
            }
        },
        handleAgeInfoUpdate: function (ageInfo) {
            var person = ageInfo.person.toLowerCase();

            if (typeof ageInfo.age !== 'undefined') {
                this.form[person + 'Age'] = ageInfo.age;
            }
            if (typeof ageInfo.bday !== 'undefined') {
                this.form[person + 'Bday'] = ageInfo.bday;
            }
        },
        toggleAges: function () {
            this.showAges = true;
            this.showCoverageArea = false;
            this.showHomeCountry = false;
            this.showLocation = false;
            this.agesOpened = true;
            this.$root.$emit('quote-modal', {
                showModal: true,
                startDate: this.form.startDate,
                validationStartDate: this.$v?.form?.startDate || null,
                form: this.form
            });
        },
        toggleCoverageArea: function () {
            this.showCoverageArea = !this.showCoverageArea;
            this.showAges = false;
            this.showHomeCountry = false;
            this.showLocation = false;
            this.covAreaOpened = true;
        },
        toggleHomeCountry: function () {
            this.showHomeCountry = !this.showHomeCountry;
            this.showAges = false;
            this.showCoverageArea = false;
            this.showLocation = false;
            this.homeCountryOpened = true;
        },
        toggleLocation: function () {
            this.showLocation = this.form.section === 'covid19' || this.form.section === 'missionaryTravel' || this.form.section === 'travelOutsideUSA' || this.form.section === 'studyAbroad' ? true : !this.showLocation;
            this.showAges = false;
            this.showCoverageArea = false;
            this.showHomeCountry = false;
            this.locationOpened = true;
            if (this.form.section === 'covid19' || this.form.section === 'missionaryTravel' || this.form.section === 'travelOutsideUSA' || this.form.section === 'studyAbroad') {
                this.$root.$emit('quote-covid-location-modal', {
                    form: this.form,
                    coverageAreaInfo: this.coverageAreaInfo
                })
            }
        },
        showModal: function (url) {
            this.$emit('show-modal', url);
        },
        resetForm: function(e){
            this.$emit('resubmit');
            this.showMobileError=false;
        	this.form.primaryAge= null;
            this.form.primaryBday= '';
            this.form.spouseAge= null;
            this.form.spouseBday= '';
            this.form.numChildren= '';
            this.form.startDate= '';
            this.form.endDate= '';
            this.form.primaryDestination='';
            this.form.location = '';
            this.form.policyMaximum= defs.policyMaximum || '';
            this.form.residenceCountry= defs.residenceCountry || '';
            this.form.homeCountry= defs.homeCountry || '';
            this.form.citizenshipCountry= defs.citizenshipCountry || '';
            this.form.coverageArea= defs.coverageArea || '';
            this.form.arrivalInUSA= defs.arrivalInUSA || '';
            this.form.arrivalTime= defs.arrivalTime || '';
            this.form.mailingState= '';
            this.form.physicalPresenceState= '';
            this.form.section= window.globalLanguage.section || '';
            this.form.showSpouseAge=false;
            this.form.showNumChildren=false;
            this.form.residenceState='';
            this.agesOpened=false;
            this.covAreaOpened=false;
            this.homeCountryOpened=false;
            this.locationOpened=false;
            this.homeCountryInfo=ibJS.homeCountryDefault;
            this.coverageAreaInfo=ibJS.coverageAreaDefault;
            if(this.form.homeCountry) {
                this.homeCountryOpened = true;
                this.homeCountryInfo = $("#HomeCountry option[value='" + this.form.homeCountry + "']:first").text();
            }
            this.$root.$emit('reset-quote-modal');
            this.$root.$emit('reset-covid-location-modal');
            this.$v.$reset();
            e.preventDefault();
        },
        displayCoverage: function(e){
            var selectedIndex = e.target.options.selectedIndex;
            this.coverageAreaInfo = e.target.options[selectedIndex]?.text;
            this.$v.form.coverageArea.$touch();
        },
        displayHomeCountry: function(e){
            var selectedIndex = e.target.options.selectedIndex;
            this.homeCountryInfo = e.target.options[selectedIndex]?.text;
            this.$v.form.homeCountry.$touch();
        },
        changeArrivalInUSA: function(e){
            this.form.arrivalTime='';
            this.form.homeCountry='';
            var selectedIndex = e.target.options.selectedIndex;
            this.coverageAreaInfo = e.target.options[selectedIndex]?.text;
            this.$v.form.coverageArea.$touch();
        },
        storeFormValuesInSession: function(formValues){
            //Inserting the formObj to the session - this session will be used at policyMax
            try{
                window.sessionStorage.setItem("insubuy-quote-form-resultObjs",JSON.stringify(formValues));
            } catch(e){
                console.log("Session Storage is not accesible");
            }
        }
    },
    computed:{
        changeCoverageArea: function(){
            if(this.form.citizenshipCountry === 'USA'){
                this.form.coverageArea = '1';
            }
        },
        displayAges: function(){
            var ageString = ibJS.editAgesText;
            if(this.form.primaryAge != null && this.form.primaryAge > 0){
                ageString = this.form.primaryAge;
                if(this.form.spouseAge != null && this.form.spouseAge > 0 && this.form.showSpouseAge){
                    ageString += ', ' + this.form.spouseAge + ' ' + ibJS.yearsText;
                }else{
                    ageString+= ' ' + ibJS.yearsText;
                }
            }
            if(this.form.primaryAge != null && this.form.showNumChildren && this.form.numChildren > 0){
                var numChildren = parseInt(this.form.numChildren);
                ageString += ', ' + numChildren + ' ' + ibJS.childrenText;
            }

            return ageString;
        },
        calculateAges: function() {
            var isVisible = false,
                showSpouseAge = this.form.showSpouseAge,
                primaryAge = this.form.primaryAge,
                spouseAge = this.form.spouseAge;

                this.priMinWithKid = false;
                this.spouseMinorAge = false;
                this.primaryMinorAge = false;

            var showChildren = this.form.showNumChildren;

            if(showSpouseAge && primaryAge){
                if(primaryAge < 18){
                    isVisible = true;
                    this.primaryMinorAge = true;
                }
                else if(spouseAge && spouseAge !== -1 && spouseAge < 18 && !isVisible) {
                    this.primaryMinorAge = false;
                    isVisible = true;
                    this.spouseMinorAge = true;
                } else{
                    this.spouseMinorAge = false;
                }
            }
            if(showChildren && primaryAge){
                if(primaryAge < 18 && this.form.numChildren > 0){
                    isVisible = true;
                    this.priMinWithKid = true;
                }else{
                    this.priMinWithKid = false;
                }
            }

            return isVisible;
        },
        showPhysicalPresenceState() {
            let isVisible = false;

            if (
                (
                    (this.form.coverageArea == '1' || this.form.coverageArea == '5' || this.form.coverageArea == '2') &&
                    (this.form.arrivalTime == '1' || this.form.arrivalTime == '2')
                ) ||
                (
                    this.form.homeCountry === 'USA' && this.form.arrivalTime == '0'
                )
            ) {
                isVisible = true;
            }

            return isVisible;
        },
        locationInfo () {
            let locationInfo = ibJS.locationDefault;
            if (this.form.mailingState && this.form.mailingState != 'OutsideUSA') {
                const $mailingState = GlobalVue.allStates?.filter(item=>item.code === this.form.mailingState && item.country === 'USA');
                if ($mailingState && $mailingState.length) {
                    // fix firefox innerText compatibility issue
                    locationInfo = $mailingState[0].state;
                }
            } else if (this.form.mailingState === 'OutsideUSA') {
                locationInfo = window.ibJS?.outsideUSAText || 'Outside USA';
            }

            return locationInfo;
        }
    }
});

